/* .card-b{
    border-bottom: 1pt solid #89CFF0;
    width: min-content;
} */

/* .ino-box{
    border: 2pt solid #89CFF0;
    width: fit-content;
    height: 60px;
} */

/* .hline{
    width: 70px;
    border-bottom: 2pt solid #89CFF0;
    text-align: center;
    margin-left: -80px;
    margin-block-start: -30px;
} */

/* .c-text-align{
    margin-top: 30px;
} */

/* .vline{
    position: absolute;
    margin-left: -34px;
    height: 730px;
    border-left: 2pt solid #89CFF0;
} */

.vline{
    display: none;
}

.hline{
    display: none;
}


@media (min-width: 992px) and (max-width: 1400px)
{.incontainer {
    max-width: 700px !important;
}
}

/* @media (max-width: 5000px)
{
    .vline{
        display: block;
    }
    .hline{
        display: block;
    }
    .c-text-align{
        display: block;
    }
    .ino-box{
        display: block;
    }
} */

@media (max-width: 5000px)
{
    /* .ino-box{
        margin-left: -298px;
    } */
}

@media (min-width: 1400px)
{
    .incontainer {
        max-width: 950px !important;
    }
    .hline{
        display: block;
        width: 70px;
        border-bottom: 2pt solid #89CFF0;
        text-align: center;
        margin-left: -80px;
        margin-block-start: -31px;
        margin-bottom: 29px;
    }
    .vline{
            display: block;
            position: absolute;
            margin-left: -34px;
            height: 552px;
            border-left: 2pt solid #89CFF0;
        }
    .innov-block{
        height: 250px;
    }
    .ino-box{
        margin-left: -400px;
        border: 2pt solid #89CFF0;
        max-width: 250px;
        max-height: 60px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px)
{
    /* .ino-box{
        margin-left: -238px;
    }  */
    .hline{
        display: block;
        width: 70px;
        border-bottom: 2pt solid #89CFF0;
        text-align: center;
        margin-left: -80px;
        margin-block-start: -31px;
        margin-bottom: 29px;
    }
    .vline{
            display: block;
            position: absolute;
            margin-left: -34px;
            height: 652px;
            border-left: 2pt solid #89CFF0;
        }
    .innov-block{
        height: 300px;
    }
    .ino-box{
        margin-left: -335px;
        border: 2pt solid #89CFF0;
        max-width: 250px;
        max-height: 60px;
    }
}

@media (min-width: 992px) and (max-width: 1199px)
{
    .hline{
        display: block;
        width: 40px;
        border-bottom: 2pt solid #89CFF0;
        text-align: center;
        margin-left: -50px;
        margin-block-start: -31px;
        margin-bottom: 29px;
    }
    .vline{
            display: block;
            position: absolute;
            margin-left: -4px;
            height: 652px;
            border-left: 2pt solid #89CFF0;
        }
    .innov-block{
        height: 300px;
    }
    .ino-box{
        margin-left: -220px;
        border: 2pt solid #89CFF0;
        max-width: 210px;
        max-height: 60px;
    }
}

/* @media (min-width: 768px) and (max-width: 991px)
{
    .hline{
        display: block;
        width: 25px;
        border-bottom: 2pt solid #89CFF0;
        text-align: center;
        margin-left: -30px;
        margin-block-start: -31px;
        margin-bottom: 29px;
    }
    .vline{
            display: block;
            position: absolute;
            margin-left: 16px;
            height: 652px;
            border-left: 2pt solid #89CFF0;
        }
    .innov-block{
        height: 300px;
    }
    .ino-box{
        margin-left: 16px;
        border: 2pt solid #89CFF0;
        max-width: 210px;
        max-height: 60px;
    }
} */

@media (max-width: 576px)
{
    /* .hline{
        display: block;
        width: 20px;
        border-bottom: 2pt solid #89CFF0;
        text-align: center;
        margin-left: -30px;
        margin-block-start: -31px;
        margin-bottom: 29px;
    }
    .vline{
            display: block;
            position: absolute;
            margin-left: 16px;
            height: 552px;
            border-left: 2pt solid #89CFF0;
        }
    .innov-block{
        height: 650px;
    }
    .ino-box{
        margin-left: -10px;
        border: 2pt solid #89CFF0;
        max-width: 210px;
        max-height: 60px;
    } */
}