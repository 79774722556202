/* .v-desc{
    margin: 0 auto;
}

.v-desc::before,
.v-desc::after {
  content: "";
  position: absolute;
  top: 0;
  
  display: block;
  width: 35%;
  height: 2px;
  background-color: #89CFF0;
}

.v-desc::before {
    left: 0;
  }

.v-desc::after {
    right: 0;
  } */

.p-box{
    border-left: 1pt solid #89CFF0;
    border-bottom: 1pt solid #89CFF0;
    padding-left: 10px;
    padding-bottom: 10px;
    margin-top: -8px;
}

.p-box-font{
    padding-top: 5px;
} 

.h-box{
    border: 1pt solid #89CFF0;
    width: fit-content;
    margin-left: -20px !important;
    padding: 3px !important;
    padding-inline: 15px !important;
}

.fst-italic{
    padding-top: 10px;
    margin-left: 50px;
}

@media (max-width: 576px)
{
    .fst-italic{
        margin-left: 10px;
    }
}