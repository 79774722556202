.dropz{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    width: 15%;
    margin: 0;
    padding: 0;
    /* background-color:; */
    list-style-type: none;
}