.previous-programs-section {
    padding: 2rem 0;
    background-color: #f7f7f7;
}

.program-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.program-container:hover {
    transform: scale(1.05);
}

.program-description h4 {
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}
