.contact-box{
    border: 2pt solid #89CFF0;
    width: fit-content;
    /* margin-left: -20px;
    padding: 3px;
    padding-inline: 15px; */
}

.contact-p-box{
    width: fit-content;
    border-left: 2pt solid #89CFF0;
    border-bottom: 2pt solid #89CFF0;
    margin-left: 65px;
    padding-left: 10px;
    padding-bottom: 10px;
    margin-top: -8px;
}

@media (min-width: 1400px){
    .contact-m-block{
        margin-top: 40px !important;
    }
    .contact-box-1{
        margin-inline: 200px;
    }
}

@media (min-width: 1200px) and (max-width:1399px){
    .contact-m-block{
        margin-top: 100px !important;
    }
    .contact-box-1{
        margin-inline: 150px;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .contact-m-block{
        margin-top: 100px !important;
    }
    .contact-box-1{
        margin-inline: 130px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .contact-box-1{
        margin-inline: 100px;
    }
} 

@media (min-width: 600px) and (max-width: 768px){
    .contact-box-1{
        margin-inline: 100px;
    }
}

/* @media (max-width: 400px){

} */

.footer-text {
    text-align: center;
    margin: 0 auto;
}
  