@media (min-width: 401px){
    .container-mobile{
        display: none;
    }
    .container-web{
        margin-top: 2%;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 400px){
    .container-web{
        display: none;

    }
    .container-mobile{
        margin-top: 6%;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

h1,
h3,
h5 {
  transition: transform 0.2s, color 0.2s;
}

h1:hover,
h3:hover,
h5:hover {
  transform: scale(1.05);
  color: #2007ab;
}

.container-web,
.container-mobile {
  transition: transform 0.2s;
  width: 100%;
  height: 100vh;
  border: none;
}

.container-web:hover,
.container-mobile:hover {
  transform: scale(1.02);
}

@media (min-width: 768px) {
  .container-web {
    width: 60%;
    margin-left: 20%;
  }
}

@media (max-width: 768px) {
  .container-mobile {
    width: 90%;
    margin-left: 5%;
    height: 80vh;
  }
}
.form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
