.slideheight{
    height: 5%;
    width: 100%;
}
.navbar{
    position: fixed;
    top:0px;
    width: 100%;
    z-index: 10000;

}


@media (max-width: 5000px)
{
    .m-img{
        display: none;
    }
}

@media (max-width: 1400px)
{
    .m-img{
        display: none;
    }
}

@media (max-width: 1200px)
{
    .m-img{
        display: none;
    }
}

@media (max-width: 992px)
{
    .m-img{
        display: none;
    }
}

@media (max-width: 768px)
{
    .m-img{
        display: none;
    }
}

@media (max-width: 576px)
{
    .d-img{
        display: none;
    }
    .m-img{
        display: block;
    }

}