.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: 100% !important;
}

@media (max-width: 400px){
    .react-pdf__Page__annotations{
        width: fit-content !important;
        height: fit-content !important;
    }
}

@media (min-width: 400px) and (max-width: 768px){
    .react-pdf__Page__annotations{
        width: fit-content !important;
        height: fit-content !important;
    }
}