/* .nav-area a{
    background: #D3D3D3;
}
.nav-area a:hover{
    cursor: pointer;
    background: #A9A9A9;
}
.nav-area a:hover::before{
    border-top: 40px solid #A9A9A9;
}

.nav-area a:first-of-type:before{
    background: #A9A9A9;
    width: 10px;
    height: 0;
} */

.zindex{
    z-index: 1;
}

/* .d-click{
    text-decoration: none;
        border-bottom: 6px solid #89CFF0 !important;
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
} */

li .d-click{
    border-bottom: 6px solid #89CFF0;
}

@media (min-width: 1400px){
    .home-active{
        border-bottom: 6px solid #89CFF0 !important;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    /* .navbar .nav-link[aria-current="page"]{
        border-bottom: 6px solid #f8c348 !important;
    }  */
    .navbar ul :hover{
        color: darkgray;
    }
    .nav-sty{
        transform: skew(-25deg);
    }
    .sub-navstyle{
        margin-left: 20px !important;
        transform: skew(25deg);
    }
    .nav-decor{
        transform: skew(25deg);
    }
    /* .dropdown-menu a{
        transform: skew(0deg);
    } */
    .navbar-align{
        margin-left: -60px;
    }
    nav ul li{
        /* padding: 13px;  */
        padding-top: 13px;
        padding-bottom: 13px;
        border-top: 0px !important;
        transition: all 1s;
    }
    .nav-area{
        background-image: linear-gradient(181deg,white,#C8C8C8) !important;
        border-right: 1px solid darkgray !important; 
    }
    nav li a{
        transform: skew(0deg);
    }
    nav ul li:hover{
        background-color: darkgray !important;
    }
    .nav-search{
        color: rgb(94, 92, 92) !important;
        border: 1pt solid darkgrey !important;
    }
    .nav-search:hover{
        background-color: darkgray !important;
        color: white !important;
        border-color: darkgray !important;
    }
    .nav-search-align{
        margin-left: 20px;
    }
    /* nav ul li.active{
        background: darkgray;
        color: #fff;
    }
    nav ul li.active a{
        color: #fff !important;
    } */

    /* a:is(:link, :active, :visited).active{
        color: white !important;
        background-color: darkgray;
    } */
}

@media (min-width: 1200px) and (max-width:1399px){
    .home-active{
        border-bottom: 6px solid #89CFF0 !important;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    .nav-sty{
        transform: skew(-25deg);
    }
    .sub-navstyle{
        margin-left: 20px !important;
        transform: skew(25deg);
    }
    .nav-decor{
        transform: skew(25deg);
    }
    .navbar-align{
        margin-left: -80px;
    }
    nav ul li {
        /* padding: 13px;  */
        padding-top: 13px;
        padding-bottom: 13px;
        transition: all 1s;
    }
    .nav-area{
        background-image: linear-gradient(181deg,white,#C8C8C8) !important;
        border-right: 1px solid darkgray !important; 
    }
    nav ul li a{
        transform: skew(0deg);
    }
    .nav-link{
        font-size: 10px !important;
    }
    nav ul li:hover{
        background-color: darkgray !important;
    }
    .nav-search{
        color: rgb(94, 92, 92) !important;
        border: 1pt solid darkgrey !important;
    }
    .nav-search:hover{
        background-color: darkgray !important;
        color: white !important;
        border-color: darkgray !important;
    }
    .n-search{
        margin-inline: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .home-active{
        border-bottom: 6px solid #89CFF0 !important;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    .nav-sty{
        transform: skew(-25deg);
    }
    .sub-navstyle{
        margin-left: 20px !important;
        transform: skew(25deg);
    }
    .nav-decor{
        transform: skew(25deg);
    }
    .navbar-align{
        margin-left: -80px;
    }
    nav ul li {
        /* padding: 13px;  */
        padding-top: 13px;
        padding-bottom: 13px;
        transition: all 1s;
    }
    .nav-area{
        background-image: linear-gradient(181deg,white,#C8C8C8) !important;
        border-right: 1px solid darkgray !important; 
        border-right: 1px solid darkgray;
    }
    nav ul li a{
        transform: skew(0deg);
    }
    .nav-link{
        font-size: 8px !important;
    }
    nav ul li:hover{
        background-color: darkgray !important;
    }
    .nav-search{
        color: rgb(94, 92, 92) !important;
        border: 1pt solid darkgrey !important;
    }
    .nav-search:hover{
        background-color: darkgray !important;
        color: white !important;
        border-color: darkgray !important;
    }
    .n-search{
        margin-inline: 40px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .home-active{
        border-bottom: 6px solid #89CFF0 !important;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    .navbar-toggler{
        margin-inline: 20px;
    }
    nav ul li a{
        margin-inline: 20px;
    }
}

@media (min-width: 400px) and (max-width: 768px){
    .home-active{
        border-bottom: 6px solid #89CFF0 !important;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    .navbar-toggler{
        margin-inline: 20px;
    }
    nav ul li a{
        margin-inline: 20px;
    }
}
 
@media (max-width: 600px){
    .search-mobile{
        display: none;
    }
}

@media (max-width: 400px){
    .highlight-active{
        border-bottom: 6px solid #89CFF0 !important;
        background-color: #89CFF0;
        height: 35px;
    }
    .navbar ul .current-tab{
        text-decoration: none;
        /* border-bottom: 6px solid #89CFF0 !important; */
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
    }
    .nav-search-align{
        max-width: fit-content;
        margin-inline: 20px;
    }
    nav ul li a{
        margin-inline: 20px;
    }
    .navbar-toggler{
        margin-inline: 20px;
    }
}