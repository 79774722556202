/* #idcontact{
        text-decoration: none;
        width: 100%;
        border-radius: 1px;
        background-color: #89CFF0;
} */

@media (min-width: 1400px)
{.cform {
    max-width: 650px !important;
}}

@media (min-width: 1200px)
{.cform {
    max-width: 650px !important;
}}

@media (min-width: 992px)
{.cform {
    max-width: 650px !important;
}}

@media (min-width: 768px)
{.cform {
    max-width: 650px !important;
}}

@media (min-width: 576px)
{.cform {
    max-width: 540px;
}}